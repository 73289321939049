var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"684faf36dff00fe7bca8b182378aa40a5555e86d"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { RewriteFrames } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';

if (process.env.NEXT_PUBLIC_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://4d0a996e238bd06c4afdf6719e8efd34@o1412351.ingest.sentry.io/4505765889638400',
    denyUrls: [
      'akam/',
      'boomerang/',
      'braze',
      'maps/api',
      'otBannerSdk',
      'resources/',
      'scripttemplates',
      'sentryWrapped',
      'smartserve',
      'static/',
      'tag-builds',
      'tag/tag',
      'ymkmodule'
    ],
    ignoreErrors: [
      '_AutofillCallbackHandler',
      'Failed to fetch',
      'Failed to load script',
      'PerformanceMonitoringJavascriptInterface',
      'Script error',
      'triggerType'
    ],
    tracesSampleRate: 0.2,
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          // eslint-disable-next-line no-undef
          frame.filename = frame.filename.replace(distDir, 'app:///_next');
          frame.filename = decodeURI(frame.filename);

          return frame;
        }
      })
    ],
    beforeSend: (event) => {
      if (
        event.exception?.values?.[0].value.includes(
          'Invariant: attempted to hard navigate to the same URL'
        )
      ) {
        return null;
      }
      return event;
    }
  });
  Sentry.configureScope((scope) => {
    scope.setTag('domain', 'esteelauder.com');
    scope.setTag('locale', process.env.NEXT_PUBLIC_LOCALE);
  });
} else {
  Sentry.init({});
}
